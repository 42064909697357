/**
 * Helpers.
 *
 * @category   JavaScript
 * @subpackage CulinaryTrailsAssetsManager
 * @author     Jason D. Moss <jason@jdmlabs.com>
 * @copyright  2017 Taste of Nova Scotia. All rights reserved.
 * @link       https://novascotiaculinarytrails.com/
 */


/**
 * Server domain.
 */
var serverDomain = "https://goodcheertrail.com/";

/**
 * Development purposes; may remove after launch.
 */
var host = window.location.host;

if (host === "goodcheer.sofar.link") {
    serverDomain = "https://goodcheer.sofar.link/";
} else if (host === "trails.local:8040") {
    serverDomain = "http://trails.local:8040/"; // Password protected.
}

var dashboard = serverDomain +"/passport/my-passport/";


/**
 * Does "this" exist?
 *
 * @param {Object|Node} elem Element.
 *
 * @return {Boolean}
 */
function exists(elem)
{
    "use strict";

    if (typeof elem === "undefined" || elem === null || elem === false) {
        return false;
    }

    return elem.length > 0;
}


/**
 * Check if {String} is in {Array}.
 *
 * @param {String} needle
 * @param {Array}  haystack
 *
 * @returns {Boolean}
 */
function inArray(needle, haystack)
{
    "use strict";

    var length = haystack.length;
    for (var i = 0; i < length; i++) {
        if (haystack[i] == needle) {
            return true;
        }
    }

    return false;
}


/**
 * Concatenate individual arrays into single array for easier manipulation.
 *
 * @param {Array} Individual arrays; as many as required.
 *
 * @returns {Array} Concatenated array.
 */
function mergeArrays()
{
    "use strict";

    return [].concat.apply([], arguments);
}


/**
 * Convert base64 string to an ArrayBuffer.
 *
 * @param {String} base64
 *
 * @returns {Binary}
 */
function base64ToArrayBuffer(base64)
{
    "use strict";

    base64 = base64.replace(/^data\:([^\;]+)\;base64,/gmi, "");

    var binaryString = atob(base64);
    var len = binaryString.length;
    var bytes = new Uint8Array(len);

    for (var i = 0; i < len; i++) {
        bytes[i] = binaryString.charCodeAt(i);
    }

    return bytes.buffer;
}


/**
 * Convert an ArrayBuffer to a base64 string.
 *
 * @param {Binary} buffer
 *
 * @returns {String}
 */
function arrayBufferToBase64(buffer)
{
    "use strict";

    var binary = "";
    var bytes = new Uint8Array(buffer);
    var len = bytes.byteLength;

    for (var i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
    }

    return window.btoa(binary);
}


/**
 * Add one or more listeners to an element.
 *
 * @param {DOMElement} element - DOM element to add listeners to.
 * @param {string} eventNames - space separated list of event names, e.g. 'click change'.
 * @param {Function} listener - function to attach for each event as a listener.
 */
function addListenerMulti(element, eventNames, listener)
{
    "use strict";

    eventNames.split(" ").forEach(function (event, index) {
        element.addEventListener(event, listener, false);
    });
}
// function addListenerMulti(element, eventNames, listener)
// {
//     var events = eventNames.split(" ");
//     for (var i = 0, iLen = events.length; i < iLen; i++) {
//         element.addEventListener(events[i], listener, false);
//     }
// }



/**
 * Lowercase the first charater of a string.
 *
 * @param {String} string
 *
 * @return {String}
 */
function lcFirst(string)
{
    "use strict";

    return string.charAt(0).toLowerCase() + string.slice(1);
}


/**
 * Capitalize the first charater of a string.
 *
 * @param {String} string
 *
 * @return {String}
 */
function ucFirst(string)
{
    "use strict";

    return string.charAt(0).toUpperCase() + string.slice(1);
}


/**
 * Strip all HTML tags from a string.
 *
 * @param {String} input
 *
 * @returns {String} De-tagged string.
 */
var stripTags = function (input) {
    "use strict";

    var allowed = (((allowed || "") +"").toLowerCase().match(/<[a-z][a-z0-9]*>/g) || []).join("");
    var tags = /<\/?([a-z][a-z0-9]*)\b[^>]*>/gi;
    var commentsAndPhpTags = /<!--[\s\S]*?-->|<\?(?:php)?[\s\S]*?\?>/gi;

    return input.replace(commentsAndPhpTags, "").replace(tags, function ($0, $1) {
        return allowed.indexOf("<"+ $1.toLowerCase() +">") > -1 ? $0 : "";
    });
};


/**
 * Sanitize an email address for use as username: strip all HTML tags from the
 * email (security); convert all special characters (punctuation) to underscore.
 *
 * @param {String} input
 *
 * @returns {String} Sanitized username.
 */
var sanitizeUsername = function (input) {
    "use strict";

    input = stripTags(input);
    input = input.replace(/[^A-Z0-9]+/ig, "_");

    return input;
};


/* -- */


/**
 * Sub-Menu separator generator.
 *
 * @return {Element}
 */
function wpAdminParentMenuSeparator()
{
    "use strict";

    var div = document.createElement("div");
    var listitem = document.createElement("li");

    div.classList.add("separator");
    listitem.classList.add("wp-not-current-submenu", "wp-menu-separator", "custom");

    listitem.appendChild(div);

    return listitem;
}


/**
 * Sub-Menu separator generator.
 *
 * @return {Element}
 */
function wpAdminChildMenuSeparator(label)
{
    "use strict";

    var span = document.createElement("span");
    var listitem = document.createElement("li");

    span.classList.add("wp-submenu-separator");
    listitem.classList.add("separator-container", "custom");

    if (label) {
        span.innerHTML = label;
        span.classList.add("labelled");
    }

    listitem.appendChild(span);

    return listitem;
}


/**
 * tabHandler: Handle tabs,
 *
 * @param {Event}
 *
 * @returns void on NULL
 */
function tabHandler(tabs, evt)
{
    "use strict";

    for (var iter = 0; iter < tabs.length; iter++) {
        tabs[iter].classList.remove("active");
    }

    var clickedTab = evt.currentTarget;
    clickedTab.classList.add("active");

    evt.preventDefault();

    var contentPanes = document.querySelectorAll(".tab-panels .tpanel");
    for (iter = 0; iter < contentPanes.length; iter++) {
        contentPanes[iter].classList.remove("active");
    }

    var anchorReference = evt.target;
    var activePaneId = anchorReference.getAttribute("href") ||
        anchorReference.querySelectorAll("a")[0].getAttribute("href");
    var activePane = document.querySelector(activePaneId);

    activePane.classList.add("active");
}

/* -- */
